<template>
    <div id="app">
        <NavigationBar v-if="$route.meta.isKeepAlive"></NavigationBar>
        <!-- <keep-alive>
            <router-view v-if="isRouterAlive && $route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="isRouterAlive && !$route.meta.keepAlive"></router-view> -->
        <keep-alive :include="aliveRoutes" max="1">
            <router-view v-if="isRouterAlive"></router-view>
        </keep-alive>
        <!-- <BottomMenu v-if="$route.meta.isKeepAlive"></BottomMenu> -->
        <TabBar v-if="$route.meta.isKeepAlive"></TabBar>
    </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
import BottomMenu from '@/components/BottomMenu.vue'
import TabBar from '@/components/TabBar.vue'

import { mapState, mapActions } from 'vuex'

export default {
    provide() {
        return {
            reload: this.reload
        }
    },
    metaInfo() {
        let meta = this.$store.state.metaInfo;

        let title = meta.title || '宝元云商';
        let Keywords = meta.Keywords || '宝元云商';
        let description = meta.description || '宝元云商';
        // if(!this.$route.meta.title) {
        // 	title = '宝元云商';
        // 	Keywords = '宝元云商';
        // 	description = '宝元云商';
        // }
        return {
            title: title,
            meta: [
                { name: "Keywords", content: Keywords },
                { name: "description", content: description }
            ]
        }
    },
    data() {
        return {
            isRouterAlive: true
        }
    },
    computed: {
        ...mapState(['aliveRoutes'])
    },
    components: {
        NavigationBar,
        BottomMenu,
        TabBar
    },
    watch: {
        '$route'(to, from) {
            setTimeout(e => {
                document.body.scrollTop = 0 + Math.random();
                document.documentElement.scrollTop = 0 + Math.random();
            })
            if (to.name == 'goodsDetails' || to.name == 'fixedDetails' || to.name == 'variableDetails' || to.name == 'suitDetails' || to.name == 'customDetails') {
                this.getAliveRoutes('goodsList')
            } else {
                this.getAliveRoutes('')
            }
        }
    },
    created() {
        console.log(window);
        let url = window.location.href;
        console.log(url, "地址栏");
        if (url.indexOf("#") !== -1) {
            let result = url.slice(url.indexOf("#") + 1); // 找到 # 的位置
            console.log("result: ", result);
            localStorage.removeItem("token");
            localStorage.removeItem("userInfo");
            this.buInfo(result);
            localStorage.setItem('url_key', result);
            console.log(result, "输出#后面的的参数"); //
        } else {
            console.log("啥也不干");
        }

        // ios上禁止页面缩放
        window.onload = function () {
            document.addEventListener("touchstart", function (event) {
                if (event.touches.length > 1) {
                    event.preventDefault()
                }
            })
            document.addEventListener("gesturestart", function (event) {
                event.preventDefault()
            })
        }
    },
    mounted() {
        this.$store.commit('gotToken');
        if (localStorage.getItem('homeBase')) this.$store.commit('gotHomeBase', JSON.parse(localStorage.getItem('homeBase')));
        this.$store.dispatch('getHomeBase');
        if (localStorage.getItem('token')) this.$store.dispatch('getuserInfo');
        // if(process.env.VUE_APP_URL == 'https://tmt-china-api.jooneit.cn/') this.loadScript();

        this.resize();
        window.onresize = () => {
            this.resize();
        }
    },
    methods: {
        ...mapActions(['getAliveRoutes']),
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(() => {
                this.isRouterAlive = true;
            })
        },
        // 加载腾讯地图
        // loadScript() {
        //     var script = document.createElement("script");
        //     script.type = "text/javascript";
        //     script.src = "https://map.qq.com/api/gljs?v=1.exp&key=GPWBZ-Q32WR-ETHWU-WARHJ-X2D7F-UKBWY&callback=initMap";
        //     document.body.appendChild(script);
        // },

        resize() {
            let userAgentInfo = navigator.userAgent;
            let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
            let getArr = Agents.filter(i => userAgentInfo.includes(i));
            let { pathname, search } = location;
            if (!getArr.length) {
                // let URL = process.env.NODE_ENV == 'production' ? 'https://www.ju-mart.com/' : 'https://tmt3d.jooneit.cn/';
                let URL = process.env.VUE_APP_URL;
                let tid = this.getQueryString('oid');
                let aid = this.getQueryString('tid');
                let link = {
                    '/fixedDetails': `${URL}designDetails${search}`,
                    '/variableDetails': `${URL}variableDetails${search}`,
                    '/suitDetails': `${URL}commodityDetails${search}`,
                    '/goodsDetails': `${URL}details${search}`,
                    '/articleList': `${URL}article?tid=${tid}&aid=${aid}`,
                }
                location.href = link[pathname] ? link[pathname] : URL;
            }
        },
        getQueryString(name) {
            var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
            var r = window.location.search.substr(1).match(reg);
            if (r != null) {
                return unescape(r[2]);
            }
            return null;
        },
        buInfo(result) {
            this.$api.getBuinfo({ b_key: result }).then((res) => {
                console.log("商户ID", res);
                if (res.code != 200) {
                    window.location.href = res.data.url;
                }

            })
        }

    },
}
</script>

<style lang="scss">
@font-face {
    font-family: GillSans;
    src: url('./assets/font/Gill Sans MT Italic.ttf');
}

@font-face {
    font-family: Helveticas;
    src: url('./assets/font/Helvetica.ttc');
}

html,
body {
    width: 100%;
    height: 100%;
    user-select: none;
    background: #ffffff;
    font-family: Helveticas !important;
}

html,
body,
div,
header,
footer,
nav,
section,
table,
tr,
th,
td,
ul,
ol,
li,
dl,
dt,
dd,
em,
i,
b,
p,
s,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
img,
form,
fieldset,
legend,
input,
textarea,
select,
button {
    margin: 0;
    padding: 0;
}

ol,
ul {
    list-style: none;
}

em {
    font-style: normal;
}

img {
    border: 0;
    display: block;
}

i,
del,
em,
span {
    font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
}

textarea {
    resize: none;
    -webkit-appearance: none;
}

input,
button,
select,
textarea {
    outline: none;
    border: none;
    border-radius: 0;
}

input[type="button"],
input[type="submit"],
input[type="reset"] {
    -webkit-appearance: none;
}

a {
    color: #121212;
    text-decoration: none;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    cursor: pointer !important;
}

::-webkit-input-placeholder {
    color: #9f9f9f;
}

:-moz-placeholder {
    color: #9f9f9f;
}

::-moz-placeholder {
    color: #9f9f9f;
}

:-ms-input-placeholder {
    color: #9f9f9f;
}

.uppercase {
    text-transform: uppercase;
}

.wh100 {
    width: 100%;
    height: 100%;
}

.disabled {
    cursor: default;
    pointer-events: none;
}

.banLine {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.banLines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.pc {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.flexJA {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.flexDc {
    flex-direction: column;
}

.flexFs {
    justify-content: flex-start;
}

.flexFe {
    justify-content: flex-end;
}

.flexSb {
    justify-content: space-between;
}

.flexSa {
    justify-content: space-around;
}

.flexAis {
    align-items: start;
}

.pt46 {
    padding-top: 46px !important;
}

// #chat-widget-container {
//     opacity: 1 !important;
//     visibility: visible !important;
//     z-index: 9 !important;
// }

//  #mainCanvas {
//	top: -20px !important;
//}
</style>
