/*
 * @Author: your name
 * @Date: 2023-05-15 11:31:02
 * @LastEditTime: 2023-06-30 11:31:12
 * @LastEditors: LYNN
 * @Description: In User Settings Edit
 * @FilePath: \gemvi_mobile\src\utils\zmrequest.js
 */
/*
 * @Author: your name
 * @Date: 2023-05-12 14:16:48
 * @LastEditTime: 2023-05-12 14:25:17
 * @LastEditors: LYNN
 * @Description: In User Settings Edit
 * @FilePath: \gemvi_pc_zuanming\src\utils\zmrequest.js
 */
import axios from 'axios';
import qs from 'qs';
import Vue from 'vue'
import store from "@/store/index";
import router from '@/router/index'

import { Toast } from 'vant';

Vue.use(Toast);

const pendingMap = new Map();

const LoadingInstance = {
    _target: null,
    _count: 0
};

function myAxios(axiosConfig, loadingOptions, customOptions) {
    const service = axios.create({

        baseURL: process.env.VUE_APP_URL_ZM, // 设置统一的请求前缀
        timeout: 30000, // 设置统一的超时时长
    });

    // 自定义配置
    let custom_options = Object.assign({
        repeat_request_cancel: true, // 是否开启取消重复请求, 默认为 true
        loading: loadingOptions, // 是否开启loading层效果, 默认为false
        reduct_data_format: true, // 是否开启简洁的数据结构响应, 默认为true
        error_message_show: true, // 是否开启接口错误信息展示,默认为true
        code_message_show: false, // 是否开启code不为0时的信息提示, 默认为false
    }, customOptions);
    // 请求拦截
    service.interceptors.request.use(
        config => {
            // removePending(config);
            custom_options.repeat_request_cancel && addPending(config);
            // 创建loading实例
            // console.log(config)
            if (custom_options.loading) {
                LoadingInstance._count++;
                if (LoadingInstance._count === 1) {
                    LoadingInstance._target = Toast.loading(loadingOptions);
                }
            }
            config.headers['Url-Key'] = localStorage.getItem('url_key') ? localStorage.getItem('url_key') : ''
            // 自动携带token
            if (localStorage.getItem('token') && typeof window !== "undefined") {
                config.headers['access-token'] = localStorage.getItem('token');
            }
            let lang = localStorage.getItem('language');
            if (lang) {
                lang = JSON.parse(lang);
                config.headers['fecshop-lang'] = lang.languageCode;
                config.headers['fecshop-currency'] = lang.currencyCode;
            }
            // console.log(config)
            if (axiosConfig.method == 'post') {
                config.data = qs.stringify(config.data);
                config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
            }

            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    // 响应拦截
    service.interceptors.response.use(
        response => {
            // removePending(response.config);
            custom_options.loading && closeLoading(custom_options); // 关闭loading

            // 产品不存在或下架
            if (response.data.code == 1300001) {
                setTimeout(() => {
                    router.replace('/NotFun')
                }, 1000);
            }

            if (custom_options.code_message_show && response.data.code !== 200) {
                Toast.fail(response.data.data && response.data.data.error || response.data.message);

                return Promise.reject(response.data);
            } else if (response.data.code == 1100003) {
                localStorage.removeItem('token');
                localStorage.removeItem('userInfo');
                store.commit('exit');
                router.replace('/');
                console.log('token已失效')
                Toast.fail(response.data.data && response.data.data.error || response.data.message);
                return Promise.reject(response.data);
            } else if (response.data.code !== 200) {
                // Toast.fail(response.data)
                return Promise.reject(response.data);
            }
            // console.log(response);
            if (response.headers['access-token']) localStorage.setItem('token', response.headers['access-token']);
            return custom_options.reduct_data_format ? response.data : response;
        },
        error => {
            error.config && removePending(error.config);
            custom_options.loading && closeLoading(custom_options); // 关闭loading
            custom_options.error_message_show && httpErrorStatusHandle(error); // 处理错误状态码
            return Promise.reject(error); // 错误继续返回给到具体页面
        }
    );

    return service(axiosConfig)
}

export default myAxios;

/**
 * 处理异常
 * @param {*} error 
 */
function httpErrorStatusHandle(error) {
    // 处理被取消的请求
    if (axios.isCancel(error)) return console.error('请求的重复请求：' + error.message);
    let message = '';
    if (error && error.response) {
        switch (error.response.status) {
            case 302:
                message = '接口重定向了！';
                break;
            case 400:
                message = '参数不正确！';
                break;
            case 401:
                message = '您未登录，或者登录已经超时，请先登录！';
                break;
            case 403:
                message = '您没有权限操作！';
                break;
            case 404:
                message = `请求地址出错: ${error.response.config.url}`;
                break; // 在正确域名下
            case 408:
                message = '请求超时！';
                break;
            case 409:
                message = '系统已存在相同数据！';
                break;
            case 500:
                message = '服务器内部错误！';
                break;
            case 501:
                message = '服务未实现！';
                break;
            case 502:
                message = '网关错误！';
                break;
            case 503:
                message = '服务不可用！';
                break;
            case 504:
                message = '服务暂时无法访问，请稍后再试！';
                break;
            case 505:
                message = 'HTTP版本不受支持！';
                break;
            default:
                message = '异常问题，请联系管理员！';
                break
        }
    }
    if (error.message.includes('timeout')) message = '网络请求超时！';
    if (error.message.includes('Network')) message = window.navigator.onLine ? '服务端异常！' : '您断网了！';
    Toast.fail(message);

}

/**
 * 关闭Loading层实例
 * @param {*} _options 
 */
function closeLoading(_options) {
    if (_options.loading && LoadingInstance._count > 0) LoadingInstance._count--;
    if (LoadingInstance._count === 0) {
        LoadingInstance._target.clear();
        LoadingInstance._target = null;
    }
}

/**
 * 储存每个请求的唯一cancel回调, 以此为标识
 * @param {*} config 
 */
function addPending(config) {
    // console.log(config)
    const pendingKey = getPendingKey(config);
    config.cancelToken = config.cancelToken || new axios.CancelToken((cancel) => {
        if (!pendingMap.has(pendingKey)) {
            pendingMap.set(pendingKey, cancel);
        }
    });
}

/**
 * 删除重复的请求
 * @param {*} config 
 */
function removePending(config) {
    // console.log(config)
    const pendingKey = getPendingKey(config);
    if (pendingMap.has(pendingKey)) {
        const cancelToken = pendingMap.get(pendingKey);
        // 如你不明白此处为什么需要传递pendingKey可以看文章下方的补丁解释
        cancelToken(pendingKey);
        pendingMap.delete(pendingKey);
    }
}

/**
 * 生成唯一的每个请求的唯一key
 * @param {*} config 
 * @returns 
 */
function getPendingKey(config) {
    let {
        url,
        method,
        params,
        data
    } = config;
    // console.log(data)
    // if (typeof data === 'string') data = JSON.parse(data); // response里面返回的config.data是个字符串对象
    return [url, method, JSON.stringify(params), JSON.stringify(data)].join('&');
}







