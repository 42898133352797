/*
 * @Author: your name
 * @Date: 2023-05-15 11:30:49
 * @LastEditTime: 2023-05-15 14:14:40
 * @LastEditors: LYNN
 * @Description: In User Settings Edit
 * @FilePath: \gemvi_mobile\src\utils\zuanmingApi.js
 */
/*
 * @Author: your name
 * @Date: 2023-05-12 10:30:12
 * @LastEditTime: 2023-05-12 14:20:53
 * @LastEditors: LYNN
 * @Description: In User Settings Edit
 * @FilePath: \gemvi_pc_zuanming\src\utils\zuanmingApi.js
 */
import zmAxios from './zmrequest';

const api = {


    // 商品
    getCategoryData: (params) => zmAxios({ url: "product/list", method: 'get', params }),//商品搜索//类目-商品列表
    getProductIndex: (params) => zmAxios({ url: "product/detail", method: 'get', params }, { text: 'loading' }),		//商品详情														//商品详情//固定商品详情



   
}

export default api